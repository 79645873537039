/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from '../../../MyStartupProfile/formValidate/updates';
import renderTinyMCEField from '../../../../shared/components/form/TinyMCEField';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {children}
    </TextField>
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class SignatureTab extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      signature: PropTypes.string,
    }).isRequired,
  };

  render() {
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <span>
        <div className="card__title">
          <h5 className="bold-text">Signature</h5>
          <h5 className="subhead">
            This signature will be used when you put <strong>&#123;&#123; Signature &#125;&#125;</strong> custom
            variable in an email template.
          </h5>
        </div>
        <form className="material-form" onSubmit={handleSubmit}>
          <div className="margin10">
            <Field
              component={renderTinyMCEField}
              name="signature"
            />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button className="rounded icon icon--right icon--blue mt-5 mr-5" color="primary" type="submit">
              Save
              <ChevronRightIcon />
            </Button>
            <Button className="rounded mt-5" type="button" onClick={reset} disabled={pristine || submitting}>
              Cancel
            </Button>
          </ButtonToolbar>
        </form>
      </span>
    );
  }
}

export default reduxForm({
  form: 'startup_signature_form', // a unique identifier for this form
  validate,
})(SignatureTab);
