import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MenuIcon from 'mdi-react/MenuIcon';
import { ContactProps } from '../../../shared/prop-types/ChatProps';
import getPicture from '../../../shared/functions/getPicture';
import Alert from '../../../shared/components/alerts/Alert';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ChatTopbar = ({ onClick, contact }) => (
  <div>
    <div className="chat__topbar">
      <button className="chat__topbar-button chat__topbar-button--menu" type="button" onClick={onClick}>
        <MenuIcon className="chat__topbar-button-icon" />
      </button>
      {contact
      && (
        <Link
          className="chat__topbar-contact"
          to={contact.type === 'investor'
            ? `/investor/${contact.participant_id}`
            : `/founder/${contact.participant_id}`
          }
        >
          <span className="chat__topbar-avatar">
            <img
              src={getPicture(contact)}
              onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
              alt="ava"
            />
          </span>
          <div className="chat__topbar-contact-info">
            <p className="chat__topbar-contact-name">{contact.name}&nbsp;
              <span className={`label label__chat ${contact.type === 'investor' ? 'label-blue' : 'label-violet'}`}>
                {contact.type}
              </span>
            </p>
            <p className="chat__topbar-contact-post">{contact.type === 'startup' ? contact.startup : contact.post}</p>
          </div>
        </Link>
      ) }
      <div className="chat__bubble-date">
        {contact
        && (
          `Conversation Started on ${moment(contact.started).format('M/D/YY')}`
        ) }
      </div>
    </div>
    { contact.is_blocked
      ? (
        <Alert color="warning" className="alert--colored alert--chat">
          <p className="text-left">
            <span className="bold-text">This investor has been blocked and does not meet our
              criteria.
            </span><br />
            Prevent any further communications with this person.
          </p>
        </Alert>
      ) : ('') }
  </div>
);

ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};

export default ChatTopbar;
