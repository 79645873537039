import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import FavoriteButton from '../../../shared/components/buttons/FavoriteButton';
import NotAFitButton from '../../../shared/components/buttons/NotAFitButton';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const ProfileMain = ({
  firstName, lastName, companyRole, locationCity, locationCountry, company, picture,
  addFavorites, removeFavorites, renderPingButton, adding, favorite, clickNotAFit, removing, notAFit,
  // eslint-disable-next-line no-unused-vars
  visits, sent, opened, linkedin,
}) => (
  <div className="profile__card profile__information">
    <Row>
      <Col xs={12} md={5} lg={4} xl={3}>
        <div className="profile__avatar">
          <img
            className="investor"
            src={picture ? process.env.REACT_APP_AWS_PATH + picture : userIcon}
            onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }}
            alt="avatar"
          />
        </div>
        {/* <div className="profile__links"> */}
        {/*  {linkedin */}
        {/*  && ( */}
        {/*    <a */}
        {/*      href={linkedin} */}
        {/*      rel="noreferrer noopener" */}
        {/*      target="_blank" */}
        {/*    > */}
        {/*      <img */}
        {/*        src={`${process.env.REACT_APP_DOMAIN_URL}/images/social/li-round.png`} */}
        {/*        alt="linkedin" */}
        {/*      /> */}
        {/*    </a> */}
        {/*  )} */}
        {/* </div> */}
      </Col>
      <Col xs={12} md={{ size: 7, offset: 0 }} lg={{ size: 7, offset: 1 }} xl={{ size: 8, offset: 1 }}>
        <div className="profile__data pt-md-5 mt-5 pl-md-3 pl-lg-0 mb-5 mb-md-0">
          <br className="d-none d-md-block" />
          <p className="profile__name">{ firstName } { lastName }</p>
          <p className="profile__work">
            {companyRole
              && (
                <span>{ companyRole }</span>
              )}
            {companyRole && company
              && (
                <span> at </span>
              )}
            {company
              && (
                <span>{ company }</span>
              )}
          </p>
          <p className="profile__location">
            <MapMarkerIcon />
            { locationCity } | { locationCountry }
          </p>
          { renderPingButton }
          <FavoriteButton
            addFavorites={e => addFavorites(e)}
            entity={{ adding, favorite }}
            removeFavorites={e => removeFavorites(e)}
            displayFavorites
          />
          <NotAFitButton
            clickNotAFit={e => clickNotAFit(e)}
            entity={{ removing, notAFit }}
            displayNotAFit
          />
        </div>
      </Col>
    </Row>
    {/* <div className="profile__stats"> */}
    {/*  <div className="profile__stat"> */}
    {/*    <p className="profile__stat-number">{opened}</p> */}
    {/*    <p className="profile__stat-title">Opened<br />Conversations</p> */}
    {/*  </div> */}
    {/*  <div className="profile__stat"> */}
    {/*    <p className="profile__stat-number">{visits}</p> */}
    {/*    <p className="profile__stat-title">Visits</p> */}
    {/*  </div> */}
    {/*  <div className="profile__stat"> */}
    {/*    <p className="profile__stat-number">{sent}</p> */}
    {/*    <p className="profile__stat-title">Messages Sent</p> */}
    {/*  </div> */}
    {/* </div> */}
  </div>
);

export default ProfileMain;

ProfileMain.defaultProps = {
  firstName: '',
  lastName: '',
  companyRole: '',
  company: '',
  locationCity: '',
  linkedin: null,
  locationCountry: '',
  picture: '',
  visits: '',
  sent: '',
  opened: '',
  adding: false,
  favorite: false,
  removing: false,
  notAFit: false,
};

ProfileMain.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  linkedin: PropTypes.string,
  companyRole: PropTypes.string,
  company: PropTypes.string,
  locationCity: PropTypes.string,
  locationCountry: PropTypes.string,
  picture: PropTypes.string,
  visits: PropTypes.number,
  sent: PropTypes.number,
  opened: PropTypes.number,
  adding: PropTypes.bool,
  favorite: PropTypes.bool,
  removing: PropTypes.bool,
  notAFit: PropTypes.bool,
  renderPingButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addFavorites: PropTypes.func.isRequired,
  removeFavorites: PropTypes.func.isRequired,
  clickNotAFit: PropTypes.func.isRequired,
};
