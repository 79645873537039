import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const loadScript = () => {
  if (!document.querySelector('script[src*="hugerte"]')) {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/hugerte@1.0.4/hugerte.min.js';
    script.async = true;
    script.onload = () => console.log('HugeRTE script loaded');
    script.onerror = e => console.error('HugeRTE script failed to load:', e);
    document.head.appendChild(script);
  }
};

const imageUploadHandler = (blobInfo, success, failure) => {
  const formData = new FormData();
  formData.append('image', blobInfo.blob());

  if (!blobInfo.blob().type.includes('image')) {
    failure('The file is not a image, please try again with an image.');
    return Promise.reject();
  }

  const imageSize = Math.trunc(blobInfo.blob().size / 1000);
  if (imageSize > 3000) {
    failure('Maximum size : 3M');
    return Promise.reject();
  }

  return axios.post('/campaigns/image/add', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => {
      if (response && response.data) {
        const json = JSON.parse(response.data);
        if (json && json.location) {
          const location = process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_ATTACHMENTS_PATH + json.location;
          // Return the location directly instead of calling success()
          return location;
        }
      }
      return Promise.reject(new Error('Invalid response format'));
    })
    .catch((error) => {
      failure('Something went wrong, please try again.');
      return Promise.reject(error);
    });
};

const editorConfig = {
  height: 350,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ].join(' '),
  toolbar:
    'undo redo | formatselect | bold italic backcolor | '
    + 'alignleft aligncenter alignright alignjustify | '
    + 'bullist numlist outdent indent | removeformat | help',
  block_formats: 'Paragraph=p;Heading 3=h3',
  images_upload_handler: imageUploadHandler,
  convert_urls: false,
  entity_encoding: 'raw',
  verify_html: false,
  cleanup: false,
  paste_data_images: true,
};

const renderTinyMCEField = ({ input, meta: { error }, dispatched }) => {
  const editorRef = useRef(null);
  const [touched, setTouched] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    loadScript();

    const initInterval = setInterval(() => {
      if (window.hugerte && editorRef.current && !editorInstance) {
        clearInterval(initInterval);

        window.hugerte.init({
          ...editorConfig,
          selector: '#hugeRTE-editor',
          initial_value: input.value || '',
          setup: (editor) => {
            setEditorInstance(editor);

            editor.on('init', () => {
              editor.setContent(input.value || '');
            });

            editor.on('change', () => {
              const content = editor.getContent();
              input.onChange(content);
            });

            editor.on('blur', () => {
              const content = editor.getContent();
              input.onChange(content);
              setTouched(true);
            });
          },
        });
      }
    }, 100);

    // Only cleanup the interval, don't destroy the editor
    return () => {
      clearInterval(initInterval);
    };
  }, [input]);

  // Separate cleanup effect for component unmount
  useEffect(() => () => {
    if (editorInstance) {
      editorInstance.destroy();
    }
  }, []);

  // Update content when input value changes
  useEffect(() => {
    if (editorInstance && input.value !== editorInstance.getContent()) {
      editorInstance.setContent(input.value || '');
    }
  }, [input.value, editorInstance]);

  return (
    <div className="form__form-group-input-wrap">
      <textarea
        id="hugeRTE-editor"
        ref={editorRef}
        style={{ visibility: 'hidden' }}
        defaultValue={input.value}
      />
      {(dispatched || touched) && error && (
        <span className="form__form-group-error error-large">{error}</span>
      )}
    </div>
  );
};

renderTinyMCEField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }).isRequired,
  dispatched: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
};

renderTinyMCEField.defaultProps = {
  meta: {},
  dispatched: false,
};

export default renderTinyMCEField;
