import React, { useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import validate from './formValidate/formBlankEmail';
import renderTextFieldBasic from '../../form/TextFieldBasic';
import Alert from '../../alerts/Alert';

const loadScript = () => new Promise((resolve, reject) => {
  try {
    if (!document.querySelector('script[src*="hugerte"]')) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/hugerte@1.0.4/hugerte.min.js';
      script.async = true;
      script.onload = () => {
        if (window.hugerte) {
          resolve();
        }
      };
      script.onerror = (e) => {
        console.error('HugeRTE script failed to load:', e);
        reject(new Error('Failed to load HugeRTE script'));
      };
      document.head.appendChild(script);
    } else if (window.hugerte) {
      resolve();
    }

    const checkHugeRTE = setInterval(() => {
      if (window.hugerte) {
        clearInterval(checkHugeRTE);
        resolve();
      }
    }, 100);

    setTimeout(() => {
      clearInterval(checkHugeRTE);
      if (!window.hugerte) {
        reject(new Error('Timeout loading HugeRTE'));
      }
    }, 10000);
  } catch (error) {
    reject(error);
  }
});

const editorConfig = {
  height: 350,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ].join(' '),
  toolbar:
    'undo redo | formatselect | bold italic backcolor | '
    + 'alignleft aligncenter alignright alignjustify | '
    + 'bullist numlist outdent indent | removeformat | help',
  block_formats: 'Paragraph=p;Heading 3=h3',
  convert_urls: false,
  entity_encoding: 'raw',
  verify_html: false,
  cleanup: false,
  paste_data_images: true,
  // Add these new configurations
  force_br_newlines: false,
  force_p_newlines: true,
  forced_root_block: 'p',
  browser_spellcheck: true,
  keep_styles: true,
  resize: false,
  auto_focus: false,
  content_style: 'body { margin: 0; padding: 1rem; }',
};

const renderTinyMCE = ({ input, meta: { touched, error } }) => {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);

  const sanitizedValue = input.value || '<p><br></p>';

  const initializeEditor = async () => {
    try {
      await loadScript();

      if (!window.hugerte || !editorRef.current) {
        return undefined;
      }

      if (editorInstanceRef.current) {
        try {
          editorInstanceRef.current.destroy();
          editorInstanceRef.current = null;
        } catch (e) {
          console.error('Error destroying existing editor:', e);
        }
      }

      const existingEditor = window.hugerte.get('#hugeRTE-email-editor');
      if (existingEditor) {
        try {
          existingEditor.destroy();
        } catch (e) {
          console.error('Error destroying existing editor:', e);
        }
      }

      const editor = await window.hugerte.init({
        ...editorConfig,
        selector: '#hugeRTE-email-editor',
        initial_value: sanitizedValue,
        setup: (ed) => {
          editorInstanceRef.current = ed;

          ed.on('init', () => {
            ed.setContent(sanitizedValue);

            // Add custom handler for enter key
            ed.getBody().addEventListener('keydown', (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                // Destructure selection from editor
                const { selection } = ed;
                const range = selection.getRng();
                const currentBlock = ed.dom.getParent(range.startContainer, 'p,h1,h2,h3,h4,h5,h6,div');

                if (currentBlock) {
                  // Create new paragraph
                  const newBlock = ed.dom.create('p');
                  ed.dom.insertAfter(newBlock, currentBlock);

                  // Move cursor to new paragraph
                  const newRange = ed.dom.createRng();
                  newRange.setStart(newBlock, 0);
                  newRange.setEnd(newBlock, 0);
                  selection.setRng(newRange);

                  // Ensure new block has content
                  if (!newBlock.innerHTML) {
                    newBlock.innerHTML = '<br>';
                  }
                }
              }
            }, true);
          });

          ed.on('change', () => {
            const content = ed.getContent() || '<p><br></p>';
            input.onChange(content);
          });

          ed.on('blur', () => {
            const content = ed.getContent() || '<p><br></p>';
            input.onChange(content);
            input.onBlur(content);
          });
        },
      });

      return editor;
    } catch (e) {
      console.error('Error during editor initialization:', e);
      return undefined;
    }
  };

  useEffect(() => {
    const init = async () => {
      await initializeEditor();
      return undefined;
    };
    init();

    return () => {
      if (editorInstanceRef.current) {
        try {
          editorInstanceRef.current.destroy();
          editorInstanceRef.current = null;
        } catch (e) {
          console.error('Error destroying editor:', e);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (editorInstanceRef.current && sanitizedValue !== editorInstanceRef.current.getContent()) {
      editorInstanceRef.current.setContent(sanitizedValue);
    }
  }, [sanitizedValue]);

  return (
    <div className="form__form-group-input-wrap">
      <textarea
        id="hugeRTE-email-editor"
        ref={editorRef}
        style={{ visibility: 'hidden' }}
        defaultValue={sanitizedValue}
      />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

renderTinyMCE.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

const BlankEmailForm = ({
  handleSubmit,
  onSubmit,
  investorContactInfo,
  formError,
  user,
}) => (
  <form
    className="form form--horizontal modal-campaigns"
    onSubmit={handleSubmit(onSubmit)}
  >
    {!user.appSubmitted ? (
      <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
        You can schedule this email now but your message will not be sent until you have completed your profile
      </Alert>
    ) : !user.mailboxConnected && (
      <Alert color="warning" className="alert--colored mt-4 ml-lg-5">
        You can schedule this email now but your message will not be sent until you have connected your mailbox.
      </Alert>
    )}

    {formError && (
      <Alert color="danger" className="alert--colored ml-4 ml-lg-5">
        <p><span className="bold-text">Error!</span> {formError}</p>
      </Alert>
    )}

    <div className="focus-form mt-5 w-100 pr-0 pl-0 pr-lg-5 pl-lg-5">
      <div className="form__form-group light">
        <Field
          name="title"
          component={renderTextFieldBasic}
          type="text"
          placeholder="Subject"
        />
      </div>
      <div className="form__form-group">
        <Field
          name="content"
          component={renderTinyMCE}
        />
      </div>
    </div>

    {investorContactInfo && investorContactInfo.email && (
      <Button className="submit-campaign rounded icon icon--right icon--blue" color="primary" type="submit">
        Send Email
        <ChevronRightIcon />
      </Button>
    )}
  </form>
);

BlankEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    templates: PropTypes.object,
  }),
  investorContactInfo: PropTypes.shape({
    contactId: PropTypes.number,
    fullName: PropTypes.string,
    investorId: PropTypes.number,
    email: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  activeTab: PropTypes.number.isRequired,
  formError: PropTypes.string,
  user: PropTypes.shape({
    mailboxConnected: PropTypes.bool,
    completion: PropTypes.number,
    appSubmitted: PropTypes.bool,
  }).isRequired,
};

BlankEmailForm.defaultProps = {
  initialValues: null,
  formError: null,
};

export default reduxForm({
  form: 'modalCampaigns',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(BlankEmailForm);
