import { Button } from 'reactstrap';
import EditIcon from 'mdi-react/EditIcon';
import React from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon'; // Assuming the spinner icon is available

const ButtonsElements = ({ askHowManyConnections, toggleTemplates, loading }) => (
  <div className="mt-5">
    <Button
      color="purple"
      type="button"
      className="rounded icon icon--left float-left ml-5"
      onClick={() => toggleTemplates()}
    >
      <p>
        <EditIcon />&nbsp;Linkedin Campaign
      </p>
    </Button>
    <Button
      color="primary"
      type="button"
      className={`icon rounded btn-choose ${loading ? 'icon--right icon--blue' : ''}`}
      onClick={() => askHowManyConnections()}
    >
      <p>
        {loading ? (
          <>
            In Progress&nbsp;
            <LoadingIcon className="mdi-icon-spinning" /> {/* Keep the spinner here */}
          </>
        ) : (
          'Choose for me'
        )}
      </p>
    </Button>
  </div>
);

ButtonsElements.propTypes = {
  askHowManyConnections: PropTypes.func.isRequired,
  toggleTemplates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired, // Prop for loading state
};

export default ButtonsElements;
