/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { Field, getFormValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {
  TabContent, TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';
import renderTextFieldBasic from '../../form/TextFieldBasic';
import Alert from '../../alerts/Alert';
import ModalCampaignErrors from './ModalCampaignErrors';
import { ModalCampaignFormButton, ModalCampaignFormMultiButtons } from './ModalCampaignFormButtons';

const loadScript = () => {
  if (!document.querySelector('script[src*="hugerte"]')) {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/hugerte@1.0.4/hugerte.min.js';
    script.async = true;
    script.onload = () => console.log('HugeRTE script loaded');
    script.onerror = e => console.error('HugeRTE script failed to load:', e);
    document.head.appendChild(script);
  }
};

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error}
      children={children}
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rowsMax={8}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class ModalCampaignForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      templates: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        position: PropTypes.number,
        delay: PropTypes.number,
        content: PropTypes.string,
        sent: PropTypes.bool,
        send: PropTypes.bool,
        sendDate: PropTypes.string,
        sendOn: PropTypes.string,
      })),
    })).isRequired,
    initialValues: PropTypes.shape({
      templates: PropTypes.object,
    }),
    investorContactInfo: PropTypes.shape({
      contactId: PropTypes.number,
      fullName: PropTypes.string,
      investorId: PropTypes.number,
      email: PropTypes.bool,
      inCampaign: PropTypes.bool,
      campaignName: PropTypes.string,
      contacted: PropTypes.bool,
      contactedOn: PropTypes.string,
      sent: PropTypes.number,
    }).isRequired,
    // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
    valuesForm: PropTypes.object,
    activeTab: PropTypes.number.isRequired,
    formError: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    campaignError: PropTypes.string,
    editCampaign: PropTypes.bool,
    multiCampaigns: PropTypes.bool,
    sendUpdate: PropTypes.bool.isRequired,
    addMultipleRecipientsToCampaign: PropTypes.func.isRequired,
    addAllRecipientsToCampaign: PropTypes.func.isRequired,
    user: PropTypes.shape({
      mailboxConnected: PropTypes.bool,
      completion: PropTypes.number,
    }).isRequired,
    formRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
  };

  static defaultProps = {
    valuesForm: null,
    initialValues: null,
    formError: null,
    campaignError: null,
    formRef: null,
    multiCampaigns: false,
    editCampaign: false,
  };

  editorConfig = {
    height: 350,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ].join(' '),
    toolbar:
      'undo redo | formatselect | bold italic backcolor | '
      + 'alignleft aligncenter alignright alignjustify | '
      + 'bullist numlist outdent indent | removeformat | help',
    block_formats: 'Paragraph=p;Heading 3=h3',
    convert_urls: false,
    entity_encoding: 'raw',
    verify_html: false,
    cleanup: false,
    paste_data_images: true,
  };

  constructor(props) {
    super(props);
    this.editorRefs = {};
    this.editorInstances = {};
    loadScript();
  }

  componentDidMount() {
    this.initializeEditorWithRetry();
  }

  componentDidUpdate(prevProps) {
    const { activeTab, investorContactInfo } = this.props;
    const prevContactId = prevProps.investorContactInfo && prevProps.investorContactInfo.contactId;
    const currentContactId = investorContactInfo && investorContactInfo.contactId;

    if (prevContactId !== currentContactId) {
      this.destroyAllEditors();
      this.initializeAllEditors();
    }

    if (activeTab !== prevProps.activeTab) {
      this.updateEditorsContent();
    }
  }

  componentWillUnmount() {
    this.destroyAllEditors();
  }

  destroyAllEditors = () => {
    Object.values(this.editorInstances).forEach((editor) => {
      if (editor) {
        try {
          editor.destroy();
        } catch (error) {
          console.error('Error destroying editor:', error);
        }
      }
    });
    this.editorInstances = {};
  };

  initializeEditorWithRetry = (editorId, input, retryCount = 0) => {
    if (retryCount > 10) return;

    if (window.hugerte && this.editorRefs[editorId] && this.editorRefs[editorId].current
      && !this.editorInstances[editorId]) {
      this.initEditor(editorId, input);
    } else {
      setTimeout(() => {
        this.initializeEditorWithRetry(editorId, input, retryCount + 1);
      }, 100);
    }
  };

  initializeAllEditors = () => {
    const { campaigns, activeTab } = this.props;
    if (!campaigns || !campaigns[activeTab] || !campaigns[activeTab].templates) {
      return;
    }

    campaigns[activeTab].templates.forEach((template, index) => {
      const editorId = `hugeRTE-campaign-modal-${index}`;
      if (!this.editorRefs[editorId]) {
        this.editorRefs[editorId] = React.createRef();
      }

      setTimeout(() => {
        const textareaElement = document.getElementById(editorId);
        if (textareaElement && !this.editorInstances[editorId]) {
          this.initEditor(editorId, {
            value: template.content || '',
            onChange: content => this.handleEditorChange(index, content),
          });
        }
      }, 100);
    });
  };

  initEditor = (editorId, input) => {
    if (!window.hugerte) {
      return;
    }

    // Cleanup any existing instance
    const existingEditor = window.hugerte.get(`#${editorId}`);
    if (existingEditor) {
      existingEditor.destroy();
      delete this.editorInstances[editorId];
    }

    window.hugerte.init({
      ...this.editorConfig,
      selector: `#${editorId}`,
      init_instance_callback: (editor) => {
        // This callback runs after the editor is fully initialized
        this.editorInstances[editorId] = editor;
        if (input.value) {
          editor.setContent(input.value);
        }
      },
      setup: (editor) => {
        editor.on('change', () => {
          const content = editor.getContent();
          input.onChange(content);
        });
      },
    });
  };

  updateEditorsContent = () => {
    const { campaigns, activeTab } = this.props;
    if (!campaigns || !campaigns[activeTab] || !campaigns[activeTab].templates) {
      return;
    }

    campaigns[activeTab].templates.forEach((template, index) => {
      const editorId = `hugeRTE-campaign-modal-${index}`;
      const editor = this.editorInstances[editorId];
      if (editor && template.content !== editor.getContent()) {
        editor.setContent(template.content || '');
      }
    });
  };


  handleEditorChange = (index, content) => {
    // eslint-disable-next-line react/prop-types
    const { onChange } = this.props;
    if (onChange) {
      // eslint-disable-next-line react/destructuring-assignment
      onChange(`campaigns[${this.props.activeTab}][templates][${index}][content]`, content);
    }
  };

  renderTinyMCE = (field) => {
    const { input } = field;
    const { touched, error } = field.meta;
    const editorId = `hugeRTE-campaign-modal-${input.name.replace(/\D+/g, '')}`;

    if (!this.editorRefs[editorId]) {
      this.editorRefs[editorId] = React.createRef();
    }

    this.initializeEditorWithRetry(editorId, input);

    return (
      <div className="form__form-group-input-wrap">
        <textarea
          id={editorId}
          ref={this.editorRefs[editorId]}
          style={{ visibility: 'hidden' }}
          defaultValue={input.value || ''}
        />
        {touched && error && <span className="form__form-group-error">{error}</span>}
      </div>
    );
  };

  renderCampaignsTabs = () => {
    const {
      campaigns,
      investorContactInfo,
      editCampaign,
    } = this.props;

    return (
      campaigns && campaigns.map((c, i) => (
        <TabPane tabId={i} key={c.id}>
          <h2 className="mt-3 mb-2">{c.name}</h2>
          <ModalCampaignErrors
            campaign={c}
            investorContactInfo={investorContactInfo}
            editCampaign={editCampaign}
          />
          {c.templates.map((t, j) => (
            <div key={t.id} className="mb-5">
              {/* eslint-disable-next-line no-nested-ternary */}
              <h3 className={`${j === 0 ? 'first' : j === 2 ? 'third' : 'other'}-subject`}>Email #{j + 1}</h3>
              {
                (editCampaign && typeof investorContactInfo.sent !== typeof undefined
                  && investorContactInfo.sent < j + 1)
                || (editCampaign && typeof t.sent !== typeof undefined
                  && !t.sent)
                || !editCampaign
                  ? (
                    <span>
                      <div className="form__form-group light subject">
                        <Field
                          name={`campaigns[${i}][templates][${j}][title]`}
                          component={renderTextField}
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                      {editCampaign && typeof t.sendOn !== typeof undefined
                        && t.send && (
                          <div className="float-right disabled">
                            <small>This email is going to be sent out on {t.sendOn}</small>
                          </div>
                      )}
                      {editCampaign && !t.send && (
                        <div className="float-right disabled">
                          <small>This email is not going to be sent out</small>
                        </div>
                      )}
                      <div className="form__form-group">
                        <Field
                          name={`campaigns[${i}][templates][${j}][content]`}
                          component={this.renderTinyMCE}
                        />
                      </div>
                      <Field
                        name={`campaigns[${i}][templates][${j}][delay]`}
                        component={renderTextFieldBasic}
                        type="hidden"
                      />
                      <Field
                        name={`campaigns[${i}][templates][${j}][position]`}
                        component={renderTextFieldBasic}
                        type="hidden"
                      />
                    </span>
                  ) : (
                    <div className="text-muted text-center m-5">This email has already been sent out.</div>
                  )}
            </div>
          ))}
        </TabPane>
      ))
    );
  };

  render() {
    const {
      handleSubmit,
      activeTab,
      onSubmit,
      investorContactInfo,
      formError,
      user,
      formRef,
      multiCampaigns,
      sendUpdate,
      editCampaign,
      addMultipleRecipientsToCampaign,
      addAllRecipientsToCampaign,
    } = this.props;

    return (
      <form
        ref={formRef}
        className="form form--horizontal overflow-hidden"
        onSubmit={handleSubmit(onSubmit.bind(this))}
      >
        {multiCampaigns && (
          <ModalCampaignFormMultiButtons
            investorContactInfo={investorContactInfo}
            addMultipleRecipientsToCampaign={count => addMultipleRecipientsToCampaign(count)}
            addAllRecipientsToCampaign={addAllRecipientsToCampaign}
            sendUpdate={sendUpdate}
            multiCampaigns
            editCampaign={editCampaign}
          />
        )}
        {formError && (
          <Alert color="danger" className="alert--colored w-100">
            <p className="text-left">
              <span className="bold-text">Error!</span>&nbsp;
              {formError}
            </p>
          </Alert>
        )}
        {user && !user.appSubmitted ? (
          <Alert color="warning" className="alert--colored ml-5 mr-2">
            <p>
              You can schedule this campaign now but your message will not be sent until you have completed your profile
            </p>
          </Alert>
        ) : user && !user.mailboxConnected && (
          <Alert color="warning" className="alert--colored ml-5 mr-2">
            <p>
              You can schedule this campaign now but your message will not be sent until you have connected your mailbox
            </p>
          </Alert>
        )}
        {investorContactInfo.smartVC ? (
          <Alert color="info" className="alert--colored ml-5 mr-2">
            <p>
              <span className="bold-text">Smart VC Contact: </span>
              This investor and all of the investor from {investorContactInfo.fund || 'this fund'} will be contacted
              one after the other. Once the campaign is over for the first investor from the list, the next investor
              will be contacted automatically.
            </p>
          </Alert>
        ) : ''}
        <TabContent activeTab={activeTab}>
          {this.renderCampaignsTabs()}
        </TabContent>
        {!multiCampaigns && (
          <ModalCampaignFormButton
            investorContactInfo={investorContactInfo}
            sendUpdate={sendUpdate}
          />
        )}
      </form>
    );
  }
}

const ConnectedModalCampaignForm = connect(state => ({
  valuesForm: getFormValues('modalCampaigns')(state),
}))(ModalCampaignForm);

export default reduxForm({
  form: 'modalCampaigns',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(ConnectedModalCampaignForm);
