/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import {
  Worker, Viewer, ProgressBar, RenderError, PageChangeEvent, SpecialZoomLevel,
} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

export default class PdfViewer extends PureComponent {
  static propTypes = {
    pdfLoaded: PropTypes.func,
    docLoaded: PropTypes.func,
    height: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    pdfLoaded: null,
    docLoaded: null,
    height: window.innerHeight - 60,
    className: 'FixedPDFViewer',
  };

  handlePageChange = (e) => {
    // eslint-disable-next-line no-console
    console.log(`Changed to page: ${e.currentPage}`);
  };

  documentLoad = () => {
    const { docLoaded } = this.props;
    if (typeof docLoaded === 'function') {
      docLoaded(true);
    }
  };

  renderError = (error) => {
    // eslint-disable-next-line no-console
    const { pdfLoaded } = this.props;
    let message = '';
    switch (error.name) {
      case 'InvalidPDFException':
        message = 'The document is invalid or corrupted. Please contact support contact@angelspartners.com';
        break;
      case 'MissingPDFException':
        message = 'The document is missing. Please contact support contact@angelspartners.com';
        break;
      case 'UnexpectedResponseException':
        message = 'Unexpected server response. Please contact support contact@angelspartners.com';
        break;
      default:
        message = 'Cannot load the document. Please contact support contact@angelspartners.com';
        break;
    }

    if (typeof pdfLoaded === 'function') {
      pdfLoaded(false);
    }

    return (
      <Container>
        <div className="load inload">
          <div className="load__icon-wrap">
            {message}
          </div>
        </div>
      </Container>
    );
  };

  render() {
    const { url, height, className } = this.props;
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <div
          className={{ className }}
          style={{ height }}
        >
          <Viewer
            /* eslint-disable-next-line max-len */
            fileUrl={url}
            renderError={this.renderError}
            defaultScale={SpecialZoomLevel.PageFit}
            onDocumentLoad={this.documentLoad}
            onPageChange={this.handlePageChange}
            renderLoader={(percentages) => (
              // You can use your own progress bar component
              <div style={{ width: '240px' }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
          />
        </div>
      </Worker>
    );
  }
}

PdfViewer.propTypes = {
  url: PropTypes.string.isRequired,
};
