import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';

import {
  Col, Row,
} from 'reactstrap';
import NotificationSystem from 'rc-notification';
import InvestorCard from './InvestorCard';

import {
  FullWideNotification, BasicNotification, LinkNotification,
} from '../../../shared/components/notifications/Notification';
import CustPag from '../../../shared/components/pagination/Pagination';
import Modal from '../../../shared/components/modals/Modal';
import ModalTrial from '../../../shared/components/modals/ModalTrial';
import ModalInput from '../../../shared/components/modals/ModalInput';
import ModalNotActiveStartup from '../../../shared/components/modals/ModalNotActiveStartup';
import EmptyResults from '../../../shared/components/externalInvestors/EmptyResults';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class List extends Component {
  constructor(props) {
    super(props);
    const initialNumberOfInvestors = 1000;
    const initialRowsCount = 21;
    const initialPageNumber = 1;
    // eslint-disable-next-line react/prop-types
    const { location } = props;

    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page'), 10);

    this.state = {
      investors: [],
      error: null,
      pageOfItems: page && page > 1 ? page : initialPageNumber,
      itemsToShow: initialRowsCount,
      itemsCount: initialNumberOfInvestors,
      showPagination: true,
      modal: false,
      modalTrial: false,
      modalPing: false,
      modalUpgrade: false,
      modalNotActiveStartup: false,
      investor: null,
      questions: null,
      questionsEnabled: false,
      isCustomer: false,
      loading: true,
      loaded: false,
      user: null,
      internal: true,
      message: null,
      previousInvId: null,
    };
  }

  componentWillReceiveProps(newProps) {
    const { props } = this;
    // eslint-disable-next-line react/prop-types
    if (props.location.search !== newProps.location.search) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        props: newProps,
        investors: [],
        loading: true,
        loaded: false,
      }, () => this.getInvestors(true));
    }
  }

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user.type !== 'startup') { window.location = '/startups'; }

    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);

    this.setState({ user }, () => {
      this.getInvestors(false);
      this.showWarnings();
    });
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  showWarnings = () => {
    const { user } = this.state;

    if (user && !user.appSubmitted) {
      this.setState({ modalNotActiveStartup: true });
    }

    setTimeout(() => {
      if (user && user.appSubmitted && !user.accepted) {
        this.showNotifWarning('Your application is still under review. '
          + 'It will only be visible to our panel of investors once we have accepted it.');
      } else if (user && user.accepted && !user.isCustomer) {
        this.showNotifUpgrade('Your application has been listed but you can only contact investors if you '
          + '<strong><u>upgrade your account</u></strong> to one of our paid plans.', '/ext/Pricing');
      }
    }, 5000);
  }

  getInvestors = (scrollToTop) => {
    const { props } = this;
    const { investors } = this.state;
    const { history } = props;
    const query = this.getQuery(new URLSearchParams(props.location.search), 0);

    if (investors.length === 0) {
      axios.get(`/investors/list?${query}`)
        .then((response) => {
          if (response.data.internal) {
            const newInvestors = response.data.investors.map(investor => (
              { ...investor, adding: false, pinging: false }
            ));
            this.setState({ investors: newInvestors, loading: false, error: null });
            setTimeout(() => this.setState({ loaded: true }), 500);

            const scrollYOffsetInt = parseInt(sessionStorage.getItem('scrollYOffsetInt'), 10);
            const scrollActiveInt = JSON.parse(sessionStorage.getItem('scrollActiveInt'));

            if (scrollToTop) {
              window.scrollTo(0, 0);
            } else if (scrollYOffsetInt > 0 && scrollActiveInt) {
              sessionStorage.setItem('scrollYOffsetInt', 0);
              sessionStorage.setItem('scrollActiveInt', false);

              let x = 0;
              const refreshIntervalId = setInterval(() => {
                x += 1;
                if (x > 30) clearInterval(refreshIntervalId);
                const newScrollYOffset = window.pageYOffset;
                if (newScrollYOffset !== scrollYOffsetInt) {
                  window.scrollTo({ top: scrollYOffsetInt, behavior: 'auto' });
                  // clearInterval(refreshIntervalId);
                }
              }, 50);
            }

            if (window.location.pathname.indexOf('/investors/') === 0) {
              history.push(`/investors/?${query}`);
            }
          } else {
            this.setState({
              investors: null, error: response.data.message, loading: false, internal: response.data.internal,
            });
            setTimeout(() => this.setState({ loaded: true }), 500);
            if (scrollToTop) window.scrollTo(0, 0);
          }
        }).catch((error) => {
          if (error && typeof error.response === 'object' && error.response.status === 402) {
            this.toggleModalUpgrade();
          }
          if (typeof error.response === 'object' && typeof error.response.data === 'object') {
            this.setState({ investors: null, error: error.response.data.message, loading: false });
          }
          // Remove loading screen
          setTimeout(() => this.setState({ loaded: true }), 500);
          if (scrollToTop) window.scrollTo(0, 0);
        });
    }
    this.getInvestorsCount(query);
  };

  getInvestorsCount = (query) => {
    axios.get(`investors/count?${query}`)
      .then((response) => {
        this.setState({
          itemsCount: response.data, showPagination: true,
        });
      });
  };

  getQuery = (query, pageOfItems) => {
    let queryUrl = '';

    if (pageOfItems > 0) {
      queryUrl += `page=${pageOfItems}`;
      this.setState({ pageOfItems });
    } else if (query.get('page')) {
      queryUrl += `page=${query.get('page')}`;
      this.setState({ pageOfItems: parseInt(query.get('page'), 10) });
    } else {
      queryUrl += 'page=1';
    }

    if (query.get('keywords')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `keywords=${query.get('keywords')}`;
    }
    if (query.get('filters')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `filters=${query.get('filters')}`;
    }
    if (query.get('rangeMin')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `rangeMin=${query.get('rangeMin')}`;
    }
    if (query.get('rangeMax')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `rangeMax=${query.get('rangeMax')}`;
    }
    if (query.get('location')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `location=${query.get('location')}`;
    }
    if (query.get('industry')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `industry=${query.get('industry')}`;
    }
    if (query.get('sortBy')) {
      queryUrl += queryUrl !== '' ? '&' : '';
      queryUrl += `sortBy=${query.get('sortBy')}`;
    }

    return queryUrl;
  };

  onChangePage = (pageOfItems) => {
    const { props } = this;
    let { user } = props;

    if (user.id === null) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    if (user.isCustomer) {
      const query = this.getQuery(new URLSearchParams(props.location.search), pageOfItems);
      this.setState({ pageOfItems }, () => props.history.push({ pathname: '/investors', search: `?${query}` }));
    } else {
      this.getInvestors(false);
      this.setState({ modalUpgrade: true });
    }
  };

  goToExtPage = () => {
    const { props } = this;
    const query = this.getQuery(new URLSearchParams(props.location.search));
    this.setState({ pageOfItems: 1 },
      () => props.history.push({ pathname: '/investors-db', search: `?${query}` }));
  }

  pingInvestor = (message) => {
    const { investor } = this.state;
    const investorId = investor.id;

    // Set up Loading
    this.updateListOfInvestor(investorId, false, null, false, true, false, null);

    // Post the Ping.
    axios.post('/investors/ping', queryString.stringify({ id: investorId, message }))
      .then((response) => {
        if (response) {
          this.setState({ modalPing: false, message: null });
          this.updateListOfInvestor(investorId, false, null, true, false, false, null);
        }
        // Remove the modal
        this.setState({ modalPing: false });
      })
      .catch((error) => {
        if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
          // Do something error.response.data.message ?
          if (error.response.status === 400) {
            this.showNotif(error.response.data.message);
          } else if (error.response.status === 403) {
            this.setState({ modalTrial: true });
          } else if (error.response.status === 402) {
            this.setState({ modal: true });
          }
        } else {
          this.showNotif('Sorry an error occurred. Please try again.');
        }
        this.updateListOfInvestor(investorId, false, null, false, false, false, null);
      });
  };

  clickFavorites = (id, e) => {
    if (e) { e.preventDefault(); }
    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];

    // Set up Loading
    const objIndex0 = this.updateListOfInvestor(id, true, null, null, false, false, null);

    // Post the Ping.
    if (!investors[objIndex0].favorite) {
      axios.post('/investors/favorite/add', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, true, null, false, false, null);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, false, null, false, false, null);
        });
    } else {
      axios.post('/investors/favorite/remove', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, false, null, false, false, null);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, true, null, false, false, null);
        });
    }
  };

  clickNotGoodFit = (id, e) => {
    if (e) { e.preventDefault(); }
    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];

    // Set up Loading
    const objIndex0 = this.updateListOfInvestor(id, false, null, null, false, true, null);

    // Post the Ping.
    if (!investors[objIndex0].notAFit) {
      axios.post('/investors/notAFit/add', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, null, null, false, false, true);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, null, null, false, false, false);
        });
    } else {
      axios.post('/investors/notAFit/remove', queryString.stringify({ id }))
        .then((response) => {
          if (response) {
            this.updateListOfInvestor(id, false, null, null, false, false, false);
          }
        })
        .catch((error) => {
          if (error && typeof error.response === 'object' && typeof error.response.data === 'object') {
            // Do something error.response.data.message ?
            this.showNotif(error.response.data.message);
          } else {
            this.showNotif('Sorry, an error occurred, please try again.');
          }
          this.updateListOfInvestor(id, false, null, null, false, false, true);
        });
    }
  };

  updateListOfInvestor = (id, adding, favorite, pinged, pinging, removing, notAFit) => {
    // Set up Loading
    const { state } = this;
    const investors = [...state.investors];

    // find the index of this investor
    const objIndex = investors.findIndex(i => i.id === id);

    // Sort out Favorite
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (favorite === null) favorite = investors[objIndex].favorite;

    // Sort out Pinged
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (pinged === null) pinged = investors[objIndex].pinged;

    // Sort out Not A Fit
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    if (notAFit === null) notAFit = investors[objIndex].notAFit;

    // Update Investor
    const updatedInvestor = {
      ...investors[objIndex], adding, favorite, pinged, pinging, removing, notAFit,
    };

    // Make new list of investors
    const updatedInvestorsList = [
      ...investors.slice(0, objIndex),
      updatedInvestor,
      ...investors.slice(objIndex + 1),
    ];

    // Update the State
    this.setState({ investors: updatedInvestorsList });

    return objIndex;
  };

  showModalPing = (investor) => {
    const { previousInvId } = this.state;
    // Check that the modal is not opened and adapt the initialMessage
    // And we contact a new investor
    if (previousInvId !== investor.id) {
      // then remove the initial message
      this.setState({ message: null }, () => {
        this.setStateForModalPing(investor);
      });
    } else {
      this.setStateForModalPing(investor);
    }
  };

  setStateForModalPing = (investor) => {
    this.setState({
      modalPing: true,
      investor,
      questions: investor.questions,
      questionsEnabled: investor.questionsEnabled,
      isCustomer: investor.isCustomer,
    });
  }

  toggleModalPing = (message) => {
    const { modalPing, investor } = this.state;
    this.setState({ modalPing: !modalPing });

    // Save the message if the user re-opens the same modal
    if (modalPing) {
      this.setState({ message, previousInvId: investor.id });
    }
  };

  toggleModalNotActiveStartup = () => {
    const { modalNotActiveStartup } = this.state;
    this.setState({ modalNotActiveStartup: !modalNotActiveStartup });
  };

  toggleModalUpgrade = () => {
    const { modalUpgrade } = this.state;
    this.setState({ modalUpgrade: !modalUpgrade });
  };

  toggleModalTrial = () => {
    const { modalTrial } = this.state;
    this.setState({ modalTrial: !modalTrial });
  };

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  showNotifWarning = message => showNotification({
    notification: <BasicNotification
      color="warning"
      title="Warning!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifUpgrade = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Limited Access"
      message={message}
      link={link}
    />,
    position: 'right-up',
  });

  showNotifDanger = (message, link) => showNotification({
    notification: <LinkNotification
      color="danger"
      title="Your Startup is not listed"
      message={message}
      link={link}
    />,
    position: 'right-up',
  });

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  },
  'ltr');

  render() {
    const {
      itemsToShow, pageOfItems, itemsCount, modalUpgrade, modalTrial,
      showPagination, modal, modalPing, modalNotActiveStartup,
      loading, loaded, error, errorMessage, investor, message,
      questions, questionsEnabled, isCustomer, internal,
    } = this.state;

    const state1 = this.state;
    let investors = null;

    if (state1.investors && state1.investors.length > 0) {
      investors = state1.investors.map(
        i => (
          <InvestorCard
            investor={i}
            clickFav={e => this.clickFavorites(i.id, e)}
            clickNot={e => this.clickNotGoodFit(i.id, e)}
            ping={() => this.showModalPing(i)}
            key={i.id}
          />
        ),
      );
    }

    return (
      <Row>
        <Col xs={12} md={11} className="mb-4 mt-4 investor-list offset-md-04">
          {showPagination && loaded && !loading
          && (
            <CustPag
              showCountOnly
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
          {!loaded
            ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
            : (
              <div className="investor-items__wrap">
                <div className="investor-items">
                  {investors && investors.length > 0
                    ? investors
                    : (
                      <EmptyResults
                        error={error}
                        internal={internal}
                        goToExtPage={this.goToExtPage}
                      />
                    )}
                </div>
              </div>
            )
          }
          {showPagination && loaded && !loading
          && (
            <CustPag
              itemsCount={itemsCount}
              itemsToShow={itemsToShow}
              pageOfItems={pageOfItems}
              onChangePage={this.onChangePage}
            />
          )}
          <Modal
            color="primary"
            title="Out of Pings!"
            colored
            displayed={modal}
            toggleModal={() => this.toggleModal()}
            pings
            message={`You do not have enough Pings left to contact this investor.<br><br>
             To send your deck to this investor's mailbox and pin your profile at the top
             of their dashboard, you need to buy more Pings.<br><br>
             To do so, please visit our <a href='${process.env.REACT_APP_DOMAIN_URL}/pings'>Pricing Page</a>.`}
          />
          <ModalTrial
            color="primary"
            title="You cannot ping investors during your trial period"
            colored
            displayed={modalTrial}
            toggleModal={() => this.toggleModalTrial()}
            pings
            message={`We are sorry, you cannot contact investors until the end of your trial.<br><br>
             To send your deck to this investor's mailbox and pin your profile at the top
             of their dashboard, you need to end your trial now, or wait until it finishes.`}
          />
          <Modal
            color="primary"
            title="Upgrade Required!"
            colored
            displayed={modalUpgrade}
            toggleModal={() => this.toggleModalUpgrade()}
            message={`Please upgrade your account to one of our paid plans if you wish to review more investors.
                      <br /><br />
                      Access hundreds of active investors within our online community & search through
                      120,000+ investors listed in our investor database with all their business information.
                      <br /><br />
                      You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
                      Pricing Page</a>.`}
          />
          <ModalInput
            color="primary"
            title="Send a Message"
            header
            displayed={modalPing}
            toggleModal={newMessage => this.toggleModalPing(newMessage)}
            ping={val => this.pingInvestor(val)}
            errorMessage={errorMessage}
            investor={investor}
            questions={questions}
            questionsEnabled={questionsEnabled}
            isCustomer={isCustomer}
            initialMessage={message}
          />
          <ModalNotActiveStartup
            color="primary"
            title="Fill Out Your Profile"
            colored
            displayed={modalNotActiveStartup}
            toggleModal={this.toggleModalNotActiveStartup}
            message={`
              You need to fill out your startup application first before you are able to contact our panel of investors.
              <br><br>
              Submit your application to be listed on our platform <strong>and connect with hundreds of
              investors</strong>, who are actively prospecting and investing on Angels Partners.<br><br>
              We review every application submitted within 2 to 5 working days.<br><br>
            `}
          />
        </Col>
      </Row>
    );
  }
}


export default withRouter(connect(state => ({
  user: state.user,
}))(List));
